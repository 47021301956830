import { useEffect, useMemo, useRef, useState } from "react";
import { createContainer } from "unstated-next";
import { CreateCart, GetCart, UpdateCart } from "../api/carts";
import { CreateCartParams, GetCartParams, UpdateCartParams } from "../api/interfaces/carts.interface";
import { useRequest } from "../api/utils";
import { AccountInformation, CURRENCIES, CartItem, ProductItemInterface, VariantItem } from "../assets/interfaces";
import {
  amountFormat,
  getExchangeRates,
  phoneObjectToString,
  toAppUrl,
  toCurrency,
} from "../assets/js/utils/functions";
import { getActualPrice, sendFacebookEvent } from "../assets/js/utils/utils";
import { useModals } from "../components/hooks/useModals";
import { string } from "yup";
import { useLocalObject } from "@/components/hooks/useLocalState";
import { CheckoutForm } from "@/components/store-front/modals/checkout";
import { CreateCustomerPublic } from "@/api/orders-customers";
import { PublicCreateCustomerParams } from "@/api/interfaces/orders-customers.interface";

//handle cart link preview - done
//handle checkout - done
//handle order page - done
//handle single product page - done
//fix copy cart link button issue
//figure out why setItemQuantity isn't working
//fix update after item is deleted from cart - done
//caclulating total price considering deleted or unavailable items - done
//handles deleted & unavailable items - done
//handle syncing image variants (w options) from existing state - done

export interface CurrencyData {
  active: CURRENCIES;
  rates: { [key: string]: number };
  options: CURRENCIES[];
  defaultCurrency: CURRENCIES;
  allRates: { [key: string]: { [key: string]: number } };
  rateId: string;
  markups: { [key: string]: number };
}

const useCartContext = (initialState: {
  storeId: string;
  cartRef: string;
  account?: AccountInformation;
  initialCart?: CartItem[];
  currencySettings?: CurrencyData;
}) => {
  const {
    storeId,
    cartRef,
    initialCart,
    account,
    currencySettings = {
      active: CURRENCIES.NGN,
      rates: {},
      options: [],
      defaultCurrency: CURRENCIES.NGN,
      allRates: {},
      markups: {},
      rateId: "",
    },
  } = initialState;
  const [cart, setCart] = useState<CartItem[]>(initialCart ?? []);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const cartId = useRef("");
  const [cartError, setCartError] = useState("");
  const { modals, toggleModal } = useModals(["variants", "cart", "customer_modal"]);
  const [currentProduct, setCurrentProduct] = useState<ProductItemInterface>(null);
  const [cartLink, setCartLink] = useState("");
  const [cartIsFromServer, setCartIsFromServer] = useState(false);
  const [currencies, setCurrencies] = useState<CurrencyData>(currencySettings);
  const [customerDetailsCache, setCustomerDetailsCache] = useLocalObject<CheckoutForm>("customer-details");
  const hasCollectedCustomerInfo = !!customerId;

  const createCartReq = useRequest<CreateCartParams>(CreateCart);
  const updateCartReq = useRequest<UpdateCartParams>(UpdateCart);
  const getCartReq = useRequest<GetCartParams>(GetCart);
  const createCustomerReq = useRequest<PublicCreateCustomerParams>(CreateCustomerPublic);
  const cartRequests = useRef({ creating: false, updating: false, fetching: false });
  const isItemCart = cartRef.includes("p-");

  const minimumOrderQuantitiesReached = useMemo(() => {
    return !cart.some((c) => c.quantity < c.item.minimum_order_quantity);
  }, [cart]);

  useEffect(() => {
    if (cartId.current) {
      setCartLink(toAppUrl(`c/${cartId.current}`));
    }
  }, [cartId.current]);

  useEffect(() => {
    let carts = JSON.parse(localStorage.getItem("catlog-carts") || "{}");

    if (carts[cartRef]) {
      if (typeof carts[cartRef] !== "string") return;

      cartId.current = carts[cartRef];

      if (!initialCart) {
        //make request to fetch store cart
        getCart();
      }
    }

    const currencySettings = currencies;

    if (window && !initialCart) {
      const selectedCurrency = localStorage.getItem("selectedCurrency");

      if (selectedCurrency && currencies.options.includes(selectedCurrency as CURRENCIES)) {
        currencySettings.active = selectedCurrency as CURRENCIES;
      }
    }

    currencySettings.rates = getExchangeRates(
      currencySettings.allRates,
      currencySettings.defaultCurrency,
      currencySettings.options
    );
    setCurrencies(currencySettings);
  }, []);

  useEffect(() => {
    if (cart.length < 1) {
      setCartError("Cart is empty, add some items from the store");
    } else {
      setCartError("");
    }

    if (cart.length < 1 && !cartId.current) {
      return;
    }

    if (initialCart || cartRequests.current.fetching) return;

    if (!cartId.current && !cartRequests.current.creating) {
      handleCartCreation();
    } else if (cartRequests.current.creating) {
      //if cart is currently being created delay cart update
      const intervalFun = setInterval(() => {
        if (!cartRequests.current.creating) {
          handleCartUpdate();
          clearInterval(intervalFun);
        }
      }, 500);
    } else {
      handleCartUpdate();
    }
    // }
  }, [cart]);

  useEffect(() => {
    if (customerDetailsCache && !customerId) {
      createCustomer(customerDetailsCache);
    } else if (customerDetailsCache && customerId) {
      updateCartWithCustomer(customerId);
    }
  }, [customerDetailsCache, customerId]);

  const createCustomer = async (customerDetailsCache: CheckoutForm) => {
    const [res, err] = await createCustomerReq.makeRequest({
      store: storeId,
      phone: phoneObjectToString(customerDetailsCache.phone),
      name: customerDetailsCache.name,
      email: customerDetailsCache.email,
    });

    if (res) {
      setCustomerId(res.data.id);
    }
  };

  const updateCartWithCustomer = async (customerId: string) => {
    const [res, err] = await updateCartReq.makeRequest({
      id: cartId.current,
      customer: customerId,
    });
  };

  const handleCartCreation = async () => {
    cartRequests.current.creating = true;
    const items = cart.map(({ quantity, item_id, variant_id }) => ({ quantity, item_id, variant_id }));

    const [res, err] = await createCartReq.makeRequest({
      store: storeId,
      items,
      customer: customerId,
      currency: currencies.active,
    });

    cartRequests.current.creating = false;

    // creatingCart.current = false;

    if (err) {
      setCartError("Couldn't save cart, please reload page and retry");
      return;
    }

    updateCartId(res.data.id);

    //update the cart items from response
  };

  const handleCartUpdate = async () => {
    const items = cart.map(({ quantity, item_id, variant_id }) => ({ quantity, item_id, variant_id }));

    const [res, err] = await updateCartReq.makeRequest({
      id: cartId.current,
      store: storeId,
      items,
      customer: customerId,
      currency: currencies.active,
    });

    if (err) {
      setCartError("Couldn't save cart, please reload page and retry");
      return;
    }

    //update cart items from response
  };

  const getCart = async () => {
    cartRequests.current.fetching = true;
    const [res, err] = await getCartReq.makeRequest({ id: cartId.current });

    if (err) {
      setCartError("Could'nt fetch cart, please reload page");
      return;
    }

    cartRequests.current.fetching = false;

    const items: CartItem[] = res?.data?.items;

    const cartFromData = items.map((i) => {
      return {
        item_id: i.item_id,
        quantity: initialCart ? i.quantity : replaceWithMaxQty(i as any),
        item: i.object,
        variant_id: i?.variant_id,
        variant: i?.variant,
        ...computeAvailablity(i, i.object),
      };
    });

    setCartIsFromServer(true);
    setCart(cartFromData);
  };

  const replaceItemsData = (data: { id: string; item: ProductItemInterface; vId?: string }[]) => {
    const cartCopy: CartItem[] = JSON.parse(JSON.stringify(cart));

    for (let i = 0; i < data.length; i++) {
      const cItem = data[i];
      const { id, item, vId } = cItem;

      if (!item) continue;

      const itemIndex = cartCopy.findIndex((i) => i.item_id === id && i.variant_id === vId);

      const theItem = cartCopy[itemIndex];

      //compute availability [technically it'll always be available because user has ordered some of the items already]
      //compute quantity for cart checks - quantity user previously ordered + actual item quantity

      cartCopy[itemIndex] = {
        ...theItem,
        item: {
          ...item,
          quantity: item.quantity ? (initialCart ? theItem.quantity + item.quantity : item.quantity) : undefined,
          variants: {
            ...item.variants,
            options: item?.variants?.options.map((o) => {
              return {
                ...o,
                quantity: o.quantity > -1 ? (o.id === vId ? o.quantity + theItem.quantity : o.quantity) : undefined, //check if variant has quantity property and that it matches the current variant
              };
            }),
          },
        },
        variant: item?.variants?.options.find((v) => v?.id === theItem?.variant_id),
        ...computeAvailablity(theItem, item),
      };
    }

    setCart(cartCopy);
  };

  const computeAvailablity = (cartItem: CartItem, item: ProductItemInterface) => {
    let is_deleted = item?.is_deleted ?? false;
    let is_unavailable =
      (item.quantity === 0 && item.is_always_available) ||
      !item.available ||
      (cartItem?.variant_id
        ? Boolean(
            item?.variants?.options.find((v) => v.id === cartItem.variant_id && (!v.is_available || v.quantity < 1))
          )
        : false);

    return {
      is_unavailable: initialCart ? false : is_unavailable,
      is_deleted: is_deleted,
    };
  };

  const replaceWithMaxQty = (item: { quantity: number; variant?: VariantItem; object: ProductItemInterface }) => {
    const { quantity, variant, object } = item;

    let maxQuantity;

    if (variant) {
      maxQuantity = variant?.quantity ?? Number.POSITIVE_INFINITY;
    } else {
      maxQuantity = object?.quantity ?? Number.POSITIVE_INFINITY;
    }

    return Math.min(maxQuantity, quantity);
  };

  const updateCartId = (id: string) => {
    cartId.current = id;

    let carts = JSON.parse(localStorage.getItem("catlog-carts") || "{}");

    if (carts[cartRef] !== id) {
      carts[cartRef] = id;

      localStorage.setItem("catlog-carts", JSON.stringify(carts));
    }
  };

  //handle case when user is passing a variant that already exists and when a product with same id is already in cart but different variant
  const addOrRemoveItem = (item: ProductItemInterface, variants?: string[]) => {
    let cartCopy: CartItem[] = JSON.parse(JSON.stringify(cart));
    const itemHasVariants = item?.variants?.options.length > 0;

    if (variants && variants?.length > 0) {
      variants.forEach((v) => {
        handleItemToggle(v);
      });
    } else {
      if (itemHasVariants && !variants) {
        //if the item has variants but no variants was passed
        setCurrentProduct(item);
        toggleModal("variants");
        return;
      }

      if (!itemHasVariants) {
        handleItemToggle();
      }
    }

    function handleItemToggle(vId?: string) {
      const itemExists = cart.findIndex((i) => i?.item_id === item?.id && i?.variant_id === vId) > -1;

      if (itemExists) {
        cartCopy = [...cartCopy].filter((i) => {
          if (i.item_id === item.id) {
            return i.variant_id !== vId;
          }

          return true;
        });
      } else {
        const variant = !vId ? undefined : item?.variants.options.find((v) => vId === v.id);
        cartCopy = [...cartCopy, { quantity: 1, item_id: item?.id, item, variant_id: vId, variant }];

        if (!hasCollectedCustomerInfo) {
          setTimeout(() => {
            toggleModal("customer_modal");
          }, 2500);
        }

        // Track adding items to cart
        sendFacebookEvent("AddToCart", { content_ids: [item.id], content_name: item.name });
      }
    }

    setCartIsFromServer(false);
    setCart([...cartCopy]);

    if (isItemCart && cartCopy.length > 0 && !(cartCopy.length < cart.length)) {
      //open cart after it has been updated on a product page
      //checking for the length of the cart so the cart stays open even when deleting items/variants
      //also consider the fact that updating a variant might come with an empty array
      toggleModal("cart");
    }
  };

  const isItemInCart = (id: string) =>
    cart.find((cI) => cI.item_id === id && !(cI.is_unavailable || cI.is_deleted)) ? true : false;

  const getItemQuantity = (id: string, vId?: string) => {
    const itemIndex = cart.findIndex((i) => i.item_id === id && vId === i.variant_id);
    if (itemIndex !== -1) {
      return cart[itemIndex].quantity;
    } else {
      return 0;
    }
  };

  const updateItemQuantity = (id: string, dir: "+" | "-", vId?: string) => {
    const itemIndex = cart.findIndex((i) => i.item_id === id && vId === i.variant_id);

    if (itemIndex !== -1) {
      const cartCopy = [...cart];
      let item = cartCopy[itemIndex];
      const theItem = item.item;

      if (dir === "+") {
        const availableQuantity = (() => {
          if (theItem.is_always_available === true) return Number.MAX_SAFE_INTEGER;

          if (vId) {
            const variantQuantity =
              theItem.variants.options.find((o) => o.id === vId)?.quantity ?? Number.MAX_SAFE_INTEGER;
            return variantQuantity;
          } else {
            return theItem.quantity ?? Number.MAX_SAFE_INTEGER;
          }
        })();

        const newQuantity = item.quantity + 1;

        if (newQuantity <= availableQuantity) item.quantity = item.quantity + 1;
        else {
          setCartError(`You can only add ${availableQuantity} of this item`);
          return;
        }

        cartCopy.splice(itemIndex, 1, item);
      } else {
        if (item.quantity > 1) {
          item.quantity = item.quantity - 1;

          cartCopy.splice(itemIndex, 1, item);
        } else {
          cartCopy.splice(itemIndex, 1);
        }
      }

      setCart(cartCopy);
    }
  };

  const removeItem = (id: string, vId?: string) => {
    const itemIndex = cart.findIndex((i) => i.item_id === id && vId === i.variant_id);

    const cartCopy = [...cart];
    cartCopy.splice(itemIndex, 1);

    setCart(cartCopy);
  };

  const removeAllVariants = (id: string) => {
    const itemIndex = cart.findIndex((i) => i.item_id === id);

    const cartCopy = [...cart];
    cartCopy.splice(itemIndex, 1);

    setCart(cartCopy);
  };

  const setQuantity = (id: string, quantity: number, vId?: string) => {
    if (quantity === 0) {
      return;
    }

    const itemIndex = cart.findIndex((i) => i.item_id === id && vId === i.variant_id);

    if (itemIndex !== -1) {
      const cartCopy = [...cart];

      if (quantity > 0) {
        let item = cart[itemIndex];
        item.quantity = quantity;

        cartCopy.splice(itemIndex, 1, item);
      } else {
        cartCopy.splice(itemIndex, 1);
      }

      setCart(cartCopy);
    }
  };

  const clearCart = () => {
    setCart([]);
    cartId.current = "";

    let carts = JSON.parse(localStorage.getItem("catlog-carts") || "{}");

    delete carts[cartRef];
    localStorage.setItem("catlog-carts", JSON.stringify(carts));
  };

  const formatAsCurrency = (
    amount: number | string,
    currency?: string,
    convert: boolean = true,
    decimals: number = 2
  ): string => {
    const activeCurrency = currency || currencies.active;

    const conversionRate =
      convert && currencies.rates && currencies.rates[activeCurrency] !== undefined
        ? currencies.rates[activeCurrency]
        : 1;

    const markup = currencies.markups?.[activeCurrency] || 0;

    // Scale numbers to avoid floating-point issues
    const scale = Math.pow(10, decimals);

    const amountWithMarkup = Math.round(+amount * (1 + markup / 100) * scale) / scale;
    const convertedAmount = Math.round(amountWithMarkup * conversionRate * scale) / scale;

    return `${activeCurrency} ${amountFormat(convertedAmount, decimals)}`;
  };

  const changeCurrency = (currency: CURRENCIES) => {
    if (currencies.options.includes(currency)) {
      setCurrencies({
        ...currencies,
        active: currency,
      });

      window.localStorage.setItem("selectedCurrency", currency);
    }
  };

  function getTotalCartPrice(cart: CartItem[]) {
    const price = getTotalUnformattedCartPrice(cart);
    return formatAsCurrency(price);
  }

  function getTotalUnformattedCartPrice(cart: CartItem[]) {
    const getItemPrice = (cartItem: CartItem) => {
      return cartItem.is_deleted || cartItem.is_unavailable
        ? 0
        : cartItem.variant
        ? getActualPrice(cartItem.variant)
        : getActualPrice(cartItem.item);
    };

    const price = cart.reduce((sum, cartItem) => sum + getItemPrice(cartItem) * cartItem.quantity, 0);
    return price;
  }

  async function downloadCart(cartId: string) {
    updateCartId(cartId);
    getCart();
  }

  return {
    cart,
    addOrRemoveItem,
    isItemInCart,
    updateItemQuantity,
    price: getTotalCartPrice(cart),
    priceUnformatted: getTotalUnformattedCartPrice(cart),
    cartLink,
    cartError,
    clearCart,
    modals,
    toggleModal,
    currentProduct,
    setCartError,
    setQuantity,
    removeItem,
    removeAllVariants,
    cartId,
    replaceItemsData,
    account,
    cartIsFromServer,
    setCartIsFromServer,
    formatAsCurrency,
    currencies,
    changeCurrency,
    downloadCart,
    getItemQuantity,
    minimumOrderQuantitiesReached,
    customerDetailsCache,
    setCustomerDetailsCache,
    setCustomerId,
    storeId,
  };
};

const CartContext = createContainer(useCartContext);

export type FormatCurrencyFun = (amount: any, currency?: string, convert?: boolean, decimals?: number) => string;

export default CartContext;
