import React from "react";
import { GetPaymentMethodsParams } from "../../api/interfaces/payments";
import { GetPaymentMethods } from "../../api/payments";
import { RequestInterface, useFetcher } from "../../api/utils";
import { InvoiceInterface, INVOICE_STATUSES } from "../../assets/interfaces/invoices";
import { PaymentMethodType, PAYMENT_METHODS, CURRENCIES } from "../../assets/interfaces";
import { toCurrency } from "../../assets/js/utils/functions";
import ErrorIcon from "../../assets/icons/statuses/failed.svg";
import classNames from "classnames";
import { AppBtn } from "../ui/buttons";
import { resolvePhone } from "../../assets/js/utils/utils";
import { useModals } from "../hooks/useModals";
import Portal from "../portal";
import ShareWithFriendModal from "./share-with-friend";
import Badge from "../ui/badge";

interface IProps {
  invoiceData: {
    preview_amount: number;
    invoice: string;
    initial_status: INVOICE_STATUSES;
    currency: string;
    storeName?: string;
  };
  initiatePayment: (method: PAYMENT_METHODS) => void;
  paymentMethod: PAYMENT_METHODS;
  initiatePaymentRequest: RequestInterface;
  storePhone?: string;
  payFor?: InvoiceInterface["receiver"];
  byCustomer?: boolean;
}

const SelectPaymentMethod: React.FC<IProps> = ({
  invoiceData,
  initiatePayment,
  paymentMethod,
  initiatePaymentRequest,
  storePhone,
  payFor,
  byCustomer,
}) => {
  const { modals, toggleModal } = useModals(["share_with_friend"]);
  const { isLoading, response, error, makeRequest } = useFetcher<GetPaymentMethodsParams>(GetPaymentMethods, {
    invoice_id: invoiceData.invoice,
    country: "NG",
    type: "INVOICE",
  });
  const reqSuccessful = !isLoading && !error;
  const storePaymentMethods: PaymentMethodType[] = response?.data ?? [];
  const availablePaymentMethods = storePaymentMethods.map((m) =>
    paymentMethods(invoiceData.currency).find((p) => p.method === m.type)
  );
  const methodsToUse = [...availablePaymentMethods, payFor ? undefined : friendPay]
    .filter((m) => m !== undefined)
    .sort((a, b) => (a.rank > b.rank ? 1 : -1));

  const chatWithSeller = () => {
    window.open(`https://api.whatsapp.com/send/?phone=${resolvePhone(storePhone)}`);
  };

  const initate = (method: PAYMENT_METHODS) => {
    if (method === PAYMENT_METHODS.FRIEND) {
      toggleModal("share_with_friend");
      return;
    }

    initiatePayment(method);
  };

  return (
    <div>
      <div className="flex flex-col items-center border-b border-grey-border border-opacity-50 mb-7.5">
        <figure className="flex items-center justify-center w-15 h-15 md:h-17.5 md:w-17.5 rounded-full bg-success mb-5">
          {/* prettier-ignore */}
          <svg width="50%" viewBox="0 0 30 30" fill="none">
            <path d="M23.75 18.75C20.9875 18.75 18.75 20.9875 18.75 23.75C18.75 24.6875 19.0125 25.575 19.475 26.325C20.3375 27.775 21.925 28.75 23.75 28.75C25.575 28.75 27.1625 27.775 28.025 26.325C28.4875 25.575 28.75 24.6875 28.75 23.75C28.75 20.9875 26.5125 18.75 23.75 18.75ZM26.3375 23.2125L23.675 25.675C23.5 25.8375 23.2625 25.925 23.0375 25.925C22.8 25.925 22.5625 25.8375 22.375 25.65L21.1375 24.4125C20.775 24.05 20.775 23.45 21.1375 23.0875C21.5 22.725 22.1 22.725 22.4625 23.0875L23.0625 23.6875L25.0625 21.8375C25.4375 21.4875 26.0375 21.5125 26.3875 21.8875C26.7375 22.2625 26.7125 22.85 26.3375 23.2125Z" fill="white" />
            <path d="M27.5 9.4375V10C27.5 10.6875 26.9375 11.25 26.25 11.25H3.75C3.0625 11.25 2.5 10.6875 2.5 10V9.425C2.5 6.5625 4.8125 4.25 7.675 4.25H22.3125C25.175 4.25 27.5 6.575 27.5 9.4375Z" fill="white" />
            <path d="M2.5 14.375V20.575C2.5 23.4375 4.8125 25.75 7.675 25.75H15.5C16.225 25.75 16.85 25.1375 16.7875 24.4125C16.6125 22.5 17.225 20.425 18.925 18.775C19.625 18.0875 20.4875 17.5625 21.425 17.2625C22.9875 16.7625 24.5 16.825 25.8375 17.275C26.65 17.55 27.5 16.9625 27.5 16.1V14.3625C27.5 13.675 26.9375 13.1125 26.25 13.1125H3.75C3.0625 13.125 2.5 13.6875 2.5 14.375ZM10 21.5625H7.5C6.9875 21.5625 6.5625 21.1375 6.5625 20.625C6.5625 20.1125 6.9875 19.6875 7.5 19.6875H10C10.5125 19.6875 10.9375 20.1125 10.9375 20.625C10.9375 21.1375 10.5125 21.5625 10 21.5625Z" fill="white" />
          </svg>
        </figure>
        <div className="mb-5 flex flex-col items-center">
          <h1 className="flex flex-col items-center justify-center text-black text-xl lg:text-2xl font-medium">
            <span>Pay</span>
            <span className="font-bold">{toCurrency(invoiceData?.preview_amount, invoiceData.currency)}</span>
          </h1>
          {payFor && !byCustomer && <Badge text={`FOR ${payFor.name}`} className="mt-2.5" />}
          {byCustomer && (
            <Badge
              text={`TO ${invoiceData.storeName}`}
              className="mt-2.5 max-w-[225px] flex overflow-hidden overflow-ellipsis whitespace-nowrap"
            />
          )}
        </div>
      </div>
      {isLoading && (
        <div className="py-15 flex items-center justify-center w-full">
          <div className="spinner spinner-md text-primary-500"></div>
        </div>
      )}
      {!reqSuccessful && !isLoading && (
        <div className="py-5 flex flex-col items-center">
          <div className="w-full flex flex-col items-center">
            <h4 className={classNames("text-accent-red-500 font-bold mb-0.5 text-base sm:text-lg lg:text-xl")}>
              Couldn&apos;t load payment
            </h4>
            <p className={classNames("text-black-placeholder text-center text-xs sm:text-1xs max-w-[280px]")}>
              Please reload page or retry
            </p>
            <div className="mt-4 sm:mt-5">
              <AppBtn size="sm" onClick={makeRequest}>
                Try Again
              </AppBtn>
            </div>
          </div>
        </div>
      )}
      {reqSuccessful && availablePaymentMethods.length > 0 && (
        <ul className="rounded-20 overflow-hidden bg-grey-fields-200">
          {methodsToUse.map((p, index) => (
            <li className=" border-b border-grey-border border-opacity-50 last:border-0" key={index}>
              <button
                className="w-full flex items-center justify-between px-3.75 py-2.5"
                onClick={() => initate(p.method)}
              >
                <div className="flex items-center">
                  <figure className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-white">
                    <img
                      src={p.icon}
                      alt={p.title}
                      className={
                        [PAYMENT_METHODS.PAYSTACK, PAYMENT_METHODS.STARTBUTTON].includes(p.method)
                          ? "h-5 w-5"
                          : "h-[25px] w-[25px] rounded-full"
                      }
                    />
                  </figure>
                  <div className="ml-3.75 text-left">
                    <span className="block text-black text-sm font-medium">{p.title}</span>
                    {p.description && <span className="block text-dark text-xs">{p.description}</span>}
                  </div>
                </div>
                {initiatePaymentRequest.isLoading && paymentMethod === p.method ? (
                  <div className="spinner spinner--sm text-primary-500"></div>
                ) : (
                  <figure className="flex items-center justify-center">
                    {/* prettier-ignore */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M5.93994 13.28L10.2866 8.9333C10.7999 8.41997 10.7999 7.57997 10.2866 7.06664L5.93994 2.71997" stroke="#AAAAAA" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  </figure>
                )}
              </button>
            </li>
          ))}
        </ul>
      )}

      {reqSuccessful && response?.data.length < 1 && availablePaymentMethods.length === 0 && (
        <div className="w-full flex flex-col items-center">
          <h4 className="text-1sm sm:text-base lg:text-lg font-bold mb-0.5 text-accent-red-500">Payment Unavailable</h4>
          <p className="text-xs sm:text-sm text-black-placeholder text-center max-w-[280px]">
            No payment methods available for this invoice
          </p>
          <div className="mt-8 w-full space-y-2.5">
            <AppBtn isBlock color="primary" className="hidden lg:block" size="lg" onClick={chatWithSeller}>
              Chat with merchant
            </AppBtn>
          </div>
        </div>
      )}

      <Portal>
        <ShareWithFriendModal
          show={modals.share_with_friend.show}
          toggle={() => toggleModal("share_with_friend")}
          invoiceId={invoiceData.invoice}
        />
      </Portal>
    </div>
  );
};

const paymentMethods = (currency: string) => [
  {
    title: "Pay with Paystack",
    description: currency === CURRENCIES.GHC ? "Pay with MoMo or Card" : "For card payments",
    icon: "/images/payment-methods/paystack.png",
    method: PAYMENT_METHODS.PAYSTACK,
    rank: currency === CURRENCIES.NGN ? 1 : 2,
  },
  {
    title: "Pay with Stripe",
    description: "Cards or wallet payments",
    icon: "/images/payment-methods/stripe.png",
    method: PAYMENT_METHODS.STRIPE,
    rank: 1,
  },
  {
    title: "Pay with Leatherback",
    description: currency === CURRENCIES.CAD ? "Pay with Interac" : "Pay with card or bank",
    icon: "/images/payment-methods/leatherback.png",
    method: PAYMENT_METHODS.LEATHERBACK,
    rank: 1,
  },
  {
    title: "Pay with Zilla",
    description: "Buy now, pay later",
    icon: "/images/payment-methods/zilla.png",
    method: PAYMENT_METHODS.ZILLA,
    rank: 4,
  },
  {
    title: "Pay with Mono",
    description: "Automatic bank debit",
    icon: "/images/payment-methods/directpay.svg",
    method: PAYMENT_METHODS.MONO_DIRECT_PAY,
    rank: 5,
  },
  {
    title: "Pay with Bank Transfer",
    description: "Confirmed instantly",
    icon: "/images/payment-methods/transfer.png",
    method: PAYMENT_METHODS.TRANSFER,
    rank: 2,
  },
  {
    title: "Pay with Thepeer",
    description: "Pay from Kuda, Chipper, Pocketapp",
    icon: "/images/payment-methods/thepeer-new.jpg",
    method: PAYMENT_METHODS.THEPEER,
    rank: 3,
  },
  {
    title: "Pay with Paystack",
    description: currency === CURRENCIES.ZAR ? "Pay with Card or EFT" : "Pay with M-Pesa or Card",
    icon: "/images/payment-methods/paystack.png",
    method: PAYMENT_METHODS.STARTBUTTON,
    rank: 1,
  },
  {
    title: "Pay with Mobile Money",
    description: "Any mobile money network in Ghana",
    icon: "/images/payment-methods/mobile-money.png",
    method: PAYMENT_METHODS.MOMO,
    rank: 1,
  },
];

const friendPay = {
  title: "Share with friend to pay",
  description: "",
  icon: "/images/payment-methods/friend.png",
  method: PAYMENT_METHODS.FRIEND,
  rank: 6,
};

export default SelectPaymentMethod;
