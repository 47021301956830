import { toNaira } from "@/assets/js/utils/functions";
import React, { useEffect } from "react";

interface IProps {
  paymentData: {
    reference: string;
    email: string;
    amount: number;
    publicKey: string;
    currency?: string;
    customerName?: string;
  };
  callback: VoidFunction;
  onClose: VoidFunction;
}

const LeatherbackPayment: React.FC<IProps> = ({ paymentData, onClose, callback }) => {
  const { reference, amount, publicKey, email, currency, customerName } = paymentData;

  useEffect(() => {
    const handler = new LeatherbackPopUp({
      amount: toNaira(amount),
      currencyCode: currency,
      onSuccess: function (arg) {
        console.log(arg, "ARGUMENT");
      },
      key: publicKey,
      showPersonalInformation: false,
      customerEmail: email,
      disableCloseAfterTransaction: false,
      customerName,
      reference,
    });

    handler.generatePaymentPopUp();
  }, []);

  return null;
};

export default LeatherbackPayment;
